export enum APP_ROUTES {
  ROOT = '/',

  LOGIN = '/login',
  FORGOT_PASSWORD = '/esqueceu-senha',
  CONFIRM_PASSWORD = '/confirmar-senha',
  CONFIRM_ACCOUNT = '/confirmar-cadastro',

  DASHBOARD = '/painel',
  PORTAL_ATTENDANCES = '/atendimentos',
  SOLICITATIONS = '/atendimentos/solicitacoes',
  USERS = '/atendimentos/usuarios',
  DISEASES = '/atendimentos/enfermidades',
  ESTABLISHMENTS = '/atendimentos/estabelecimentos',
  PROFESSIONALS = '/atendimentos/profissionais',
  VIDEO_CALL = '/chamada-video/:id',

  OUVIDORIA = '/ouvidoria',
  VISITS = '/visitas',

  INDICATORS = '/previne/indicadores',
  ATTENDANCES = '/previne/atendimentos',
  CITIZENS = '/previne/cidadaos',
  PREVINE_DASHBOARD = '/previne/painel',

  IMPORTS = '/integracao',
  IMPORTS_IMPORT = '/integracao/importacoes/importar',
  IMPORTED_ESTABLISHMENTS = '/integracao/importacoes/estabelecimentos',
  IMPORTED_PROFESSIONALS = '/integracao/importacoes/profissionais',
  IMPORTED_PROCEDURES = '/integracao/importacoes/procedimentos',
  EXPORTS = '/integracao/exportacoes',
  CNES = '/integracao/cnes',
  CNS = '/integracao/cns',

  CALENDAR_VACCINE = '/calendario-vacina',

  SETTINGS = '/configuracoes/',
  SETTINGS_LOGOS = '/configuracoes/logos',
  SETTINGS_INFORMATIVE_TEXT = '/configuracoes/texto-informativo',
}
