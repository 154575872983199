import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useState } from 'react';
import PageHeader from '../../components/PageHeader';
import ExportBpa from './ExportBpa';
import ExportGeneric, { ExportProps } from './ExportGeneric';
import ExportSISAIH01 from './ExportSISAIH01';

interface ExportType {
  name: string;
  component: React.FC<ExportProps>;
  fileurl: string;
  btnText: string;
}

const exportsList: ExportType[] = [
  {
    name: 'Exportar BPA',
    component: ExportBpa,
    fileurl: '',
    btnText: 'Exportar BPA',
  },
  {
    name: 'Exportar APAC',
    component: ExportGeneric,
    fileurl: `${process.env.PUBLIC_URL}/apac.txt`,
    btnText: 'Exportar APAC',
  },
  {
    name: 'Exportar RAAS',
    component: ExportGeneric,
    fileurl: `${process.env.PUBLIC_URL}/raas.txt`,
    btnText: 'Exportar RAAS',
  },
  {
    name: 'Exportar SISAIH01',
    component: ExportSISAIH01,
    fileurl: `${process.env.PUBLIC_URL}/sisaih01.txt`,
    btnText: 'Exportar SISAIH01',
  },
];

function Exports() {
  const [exportType, setExportType] = useState('');

  const exportComponent =
    exportsList.find((e) => e.name === exportType) || null;

  return (
    <>
      <PageHeader title="Exportações" />

      <FormControl variant="outlined">
        <InputLabel>Tipo de exportação</InputLabel>
        <Select
          style={{ marginRight: 16 }}
          label="Tipo de exportação"
          variant="outlined"
          value={exportType}
          onChange={(e) => {
            setExportType(e.target.value as string);
          }}
        >
          <MenuItem value="">Selecione o Tipo de Exportação</MenuItem>
          {exportsList.map((exp) => (
            <MenuItem key={exp.name} value={exp.name}>
              {exp.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ marginBottom: '2rem' }}>
          Selecione o tipo de exportação
        </FormHelperText>
      </FormControl>

      {exportComponent && (
        <exportComponent.component
          fileurl={exportComponent.fileurl}
          btnText={exportComponent.btnText}
        />
      )}
    </>
  );
}

export default Exports;
