import {
  LocalHospital,
  ImportExport,
  Business,
  SupervisedUserCircle,
  Backup,
  CreditCard,
  Apps,
  GetApp,
} from '@material-ui/icons';
import {
  Imports,
  ImportedEstablishments,
  ImportedProfessionals,
  CNS,
  Cnes,
  Procedures,
  Exports,
} from '../../../screens';
import { Roles } from '../../../utils/roles';
import { APP_ROUTES, PrivateRoute } from '..';

const routes: PrivateRoute[] = [
  {
    title: 'Integração MS',
    path: APP_ROUTES.IMPORTS,
    exact: true,
    icon: ImportExport,
    roles: [Roles.Gestor],
    separator: true,
    nestedPages: [
      {
        title: 'Importar',
        path: APP_ROUTES.IMPORTS_IMPORT,
        component: Imports,
        icon: Backup,
        roles: [Roles.Gestor],
      },
      {
        title: 'Estabelecimentos',
        path: APP_ROUTES.IMPORTED_ESTABLISHMENTS,
        component: ImportedEstablishments,
        icon: Business,
        roles: [Roles.Gestor],
      },
      {
        title: 'Profissionais',
        path: APP_ROUTES.IMPORTED_PROFESSIONALS,
        component: ImportedProfessionals,
        icon: SupervisedUserCircle,
        roles: [Roles.Gestor],
      },
      {
        title: 'Procedimentos',
        path: APP_ROUTES.IMPORTED_PROCEDURES,
        component: Procedures,
        icon: LocalHospital,
        roles: [Roles.Gestor],
      },
      {
        title: 'Exportações',
        path: APP_ROUTES.EXPORTS,
        component: Exports,
        icon: GetApp,
        roles: [Roles.Gestor],
      },
      {
        title: 'CNES Online',
        path: APP_ROUTES.CNES,
        component: Cnes,
        icon: Apps,
        roles: [Roles.Gestor],
      },
      {
        title: 'CNS Online',
        path: APP_ROUTES.CNS,
        component: CNS,
        icon: CreditCard,
        roles: [Roles.Gestor],
      },
    ],
  },
];

export default routes;
