import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { useRef, Dispatch } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';

interface FileInputProps {
  label: string;
  accept?: string;
  file: File | null;
  setFile: Dispatch<File | null>;
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input, & .MuiInputBase-root': {
      cursor: 'pointer !important',
    },
  },
  hidden: {
    display: 'none',
  },
}));

function FileInput(props: FileInputProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const classes = useStyles();

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          fileInputRef.current?.click();
        }}
      >
        <TextField
          variant="outlined"
          label={props.label}
          value={props.file?.name || ''}
          fullWidth
          autoComplete="off"
          classes={{ root: classes.root }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <input
        className={classes.hidden}
        ref={(ref) => (fileInputRef.current = ref)}
        type="file"
        accept={props.accept}
        onChange={(e) => {
          props.setFile(e.target?.files?.[0] || null);
        }}
      />

      {/* This input prevents autocomplete from showing when clicking the main input */}
      <input type="text" className={classes.hidden} />
    </>
  );
}

export default FileInput;
