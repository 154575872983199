import { PrivateRoute } from '../types';

import PortalRoutes from './portal';
import PrevineRoutes from './previne';
import InformationRoutes from './information';

export const privateRoutes: PrivateRoute[] = (() => {
  switch (process.env.REACT_APP_BUILD_TYPE) {
    case 'previne':
      return [...PrevineRoutes[0].nestedPages!];

    default:
      return [...PortalRoutes, ...PrevineRoutes, ...InformationRoutes];
  }
})();
