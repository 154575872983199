import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Dispatch, useEffect, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../../api';
import FileInput from '../../../../components/FileInput';
import { useMessage } from '../../../../context/message';

interface ImportOnlineEstablishmentModalProps {
  open: boolean;
  setOpen: Dispatch<boolean>;
  refreshList: () => void;
}

function ImportOnlineEstablishmentModal(
  props: ImportOnlineEstablishmentModalProps,
) {
  const message = useMessage();
  const [file, setFile] = useState<File | null>(null);

  function handleClose() {
    props.setOpen(false);
  }

  async function handleSubmit() {
    try {
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append('xml', file);

      await axiosInstance.post(API_ROUTES.IMPORTS_CNES_ONLINE, formData);

      message.setMessage({
        type: 'success',
        text: 'XML importado com sucesso',
      });

      props.refreshList();
      handleClose();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.response?.data?.message || 'Ocorreu um erro ao importar o XML',
      });
    }
  }

  useEffect(() => {
    if (!props.open) {
      setFile(null);
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle>Importar CNES (XML Online)</DialogTitle>
      <DialogContent dividers>
        <FileInput
          label="Selecione o XML"
          accept=".xml"
          file={file}
          setFile={setFile}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit} disabled={!file}>
          Importar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportOnlineEstablishmentModal;
