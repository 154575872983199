import { CircularProgress } from '@material-ui/core';
import { useState, createContext, useContext, useRef, useEffect } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import { Roles } from '../../utils/roles';
import { useMessage } from '../message';
import {
  REFRESH_TOKEN_KEY,
  REMEMBER_ME_KEY,
  TOKEN_KEY,
  getStorage,
  ContextProps,
  User,
} from '.';

const authContext = createContext<ContextProps>({} as ContextProps);

function ProvideAuth({ children }: any) {
  const [loaded, setLoadedStatus] = useState(false);
  const auth = useProvideAuth();
  const authRef = useRef(auth);

  useEffect(() => {
    (async () => {
      await authRef.current.checkIfAuthenticated();
      setLoadedStatus(true);
    })();
  }, [authRef]);

  return (
    <authContext.Provider value={auth}>
      {loaded ? (
        children
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress size={80} />
        </div>
      )}
    </authContext.Provider>
  );
}

function useProvideAuth() {
  const [user, setUser] = useState<User>(null);
  const message = useMessage();

  async function fetchProfile() {
    const req = await axiosInstance.get(API_ROUTES.PROFILE);

    const user: User = {
      id: req.data.id,
      role: req.data.role,
      association: req.data.professional,
      uses2fa: req.data.use_2fa,
      profile: {
        name: req.data.name,
        cpf: req.data.cpf,
        birthDate: req.data.birth_date,
        gender: req.data.gender,
        email: req.data.email,
        phone: req.data.phone,
        uf: req.data.state || '',
        living_city: req.data.living_city || '',
        district: req.data.neighborhood || '',
        image: req.data.picture,
      },
    };

    return user;
  }

  async function checkIfAuthenticated() {
    const storage = getStorage();

    if (storage.getItem(TOKEN_KEY)) {
      try {
        await refreshAuthToken();
        const user = await fetchProfile();

        if (user.role === Roles.Padrao) {
          throw new Error();
        }

        setUser(user);
      } catch (error: any) {
        storage.removeItem(TOKEN_KEY);
      }
    }
  }

  async function refreshAuthToken() {
    const storage = getStorage();
    const refreshToken = storage.getItem(REFRESH_TOKEN_KEY);

    if (refreshToken) {
      try {
        const response = await axiosInstance.post(API_ROUTES.REFRESH_TOKEN, {
          refresh: refreshToken,
        });

        const newToken = response.data.access;

        if (newToken) {
          storage.setItem(TOKEN_KEY, newToken);
        }
      } catch (error: any) {
        // Remove the token if it expired, we don't care about anything else
        if (error.response?.status === 401) {
          storage.removeItem(REFRESH_TOKEN_KEY);
        }
      }
    }
  }

  async function login(cpf: string, password: string, keepLogged: boolean) {
    try {
      const request = await axiosInstance.post(API_ROUTES.LOGIN, {
        cpf,
        password,
      });

      if (request.data.message) {
        return '2fa';
      }

      if (request.data.user.role === Roles.Padrao) {
        throw new Error(
          'Você está cadastrado como paciente. Baixe o app para utilizar os serviços.',
        );
      }

      localStorage.setItem(REMEMBER_ME_KEY, String(keepLogged));

      const storage = getStorage();
      storage.setItem(TOKEN_KEY, request.data.token);
      storage.setItem(REFRESH_TOKEN_KEY, request.data.refresh_token);

      setUser(await fetchProfile());

      return true;
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        'Erro na autenticação';

      message.setMessage({
        text: errorMessage,
        type: 'error',
        autoHideDuration: null,
        anchor: {
          horizontal: 'center',
          vertical: 'top',
        },
      });

      return false;
    }
  }

  async function submitToken(cpf: string, token: string, keepLogged: boolean) {
    try {
      const request = await axiosInstance.post(API_ROUTES.LOGIN_2FA, {
        cpf,
        token,
      });

      if (request.data.user.role === Roles.Padrao) {
        throw new Error(
          'Você está cadastrado como paciente. Baixe o app para utilizar os serviços.',
        );
      }

      localStorage.setItem(REMEMBER_ME_KEY, String(keepLogged));

      const storage = getStorage();
      storage.setItem(TOKEN_KEY, request.data.token);
      storage.setItem(REFRESH_TOKEN_KEY, request.data.refresh_token);

      setUser(await fetchProfile());

      return true;
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || 'Erro na autenticação';

      message.setMessage({
        text: errorMessage,
        type: 'error',
        autoHideDuration: null,
        anchor: {
          horizontal: 'center',
          vertical: 'top',
        },
      });

      return false;
    }
  }

  async function logout() {
    getStorage().removeItem(TOKEN_KEY);
    setUser(null);
  }

  async function refreshProfile() {
    try {
      const user = await fetchProfile();
      setUser(user);
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text: 'Ocorreu um erro ao carregar o perfil',
      });
    }
  }

  return {
    user,
    refreshProfile,
    submitToken,
    checkIfAuthenticated,
    refreshAuthToken,
    login,
    logout,
  };
}

export function useAuth() {
  return useContext(authContext);
}

export default ProvideAuth;
